/* eslint-disable camelcase */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/nextjs'
import { useStoreActions, useStoreState } from 'easy-peasy'
import jwt_decode from 'jwt-decode'
import Image from 'next/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaUserCircle } from 'react-icons/fa'
import * as yup from 'yup'
import IdFlag from '../assets/id-flag.png'
import MyFlag from '../assets/my-flag.png'
import SinioLogo from '../assets/sinio-logomark.png'
import SinioAPIService from '../services/sinioAPI'

const Header = () => {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast({
    variant: 'toast',
    position: 'top',
    duration: 5000
  })
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const auth = useStoreState((state) => state.auth)
  const { setAuth } = useStoreActions((actions) => actions.auth)

  const country = useStoreState((state) => state.country)
  const { setCurrency, setCountryChecked } = useStoreActions(
    (actions) => actions.country
  )

  useEffect(() => {
    const getCountryFromAPI = async () => {
      const SinioAPI = SinioAPIService()
      const { data } = await SinioAPI.getCountry()

      const { countryCode } = data
      setCurrency({ currency: countryCode === 'ID' ? 'IDR' : 'MYR' })
      window.location.reload(false)
    }

    if (!country.countryChecked) {
      setCountryChecked({ countryChecked: true })
      getCountryFromAPI()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country.countryChecked])

  const isAuthenticated = !!auth?.user && !!auth?.credentials
  const schema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
    password: yup.string().required('Password is required.')
  })
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const logIn = async ({ email, password }) => {
    const SinioAPI = SinioAPIService()

    setIsLoggingIn(true)

    try {
      const { data } = await SinioAPI.logIn({
        email,
        password
      })

      // Decode the tokens
      // User details are inside the id_token payload
      const payload = jwt_decode(data.id_token)

      // NOTE: The "sub" contains the user's id, the part after |
      const authData = {
        credentials: data,
        user: {
          id: payload.sub.split('|')[1],
          name: payload.name || null,
          nickname: payload.nickname || null,
          email: payload.email,
          isEmailVerified: payload.email_verified,
          picture: payload.picture || null
        }
      }

      setIsLoggingIn(false)

      // Save auth data to store
      setAuth(authData)

      onClose()

      // Hacky way to reload a page to fetch new data for the auth user
      window.location.reload(false)
    } catch (error) {
      Sentry.captureException(error)
      const errorPayload = error.response?.data

      setIsLoggingIn(false)

      // TODO: Better error handling/sync with backend API for error codes
      toast({
        title: 'Daftar masuk tidak berjaya',
        description:
          errorPayload?.error_description ||
          'Tidak dapat daftar masuk anda pada masa ini.',
        status: 'error'
      })
    }
  }

  return (
    <Box as="header" width="100%" zIndex="10" background="#1d1d1d">
      <Box marginX="auto" maxWidth="8xl">
        <Flex
          width="100%"
          height="100%"
          padding={{ base: '3', md: '4', lg: '5' }}
          align="center"
          justify="space-between"
        >
          <NextLink href="/" passHref>
            <Link
              userSelect="none"
              _hover={{
                textDecoration: 'none'
              }}
            >
              <Stack spacing={4} direction="row" align="center">
                <Image src={SinioLogo} alt="Sinio" width={38} height={40} />
                <Stack spacing={0}>
                  <Text as="div" fontWeight={800} fontSize="2xl" lineHeight={1}>
                    Sinio
                  </Text>
                  <Text
                    as="div"
                    fontSize="xs"
                    fontWeight={600}
                    color="gray.400"
                  >
                    BETA
                  </Text>
                </Stack>
              </Stack>
            </Link>
          </NextLink>
          <Stack direction="row">
            <Menu variant="outline" color="gray">
              <MenuButton
                as={Button}
                rightIcon="⌄"
                colorScheme="white"
                variant="outline"
              >
                {country.currency === 'MYR' ? (
                  <Image src={MyFlag} alt="Malaysia" width={40} height={40} />
                ) : (
                  <Image src={IdFlag} alt="Indonesia" width={40} height={40} />
                )}
              </MenuButton>
              <MenuList bg="gray.800" minW={90}>
                {country.currency === 'IDR' && (
                  <MenuItem
                    bg="gray.800"
                    onClick={() => {
                      setCurrency({ currency: 'MYR' })
                      window.location.reload(false)
                    }}
                  >
                    <Image src={MyFlag} alt="Malaysia" width={40} height={40} />
                  </MenuItem>
                )}
                {country.currency === 'MYR' && (
                  <MenuItem
                    bg="gray.800"
                    onClick={() => {
                      setCurrency({ currency: 'IDR' })
                      window.location.reload(false)
                    }}
                  >
                    <Image
                      src={IdFlag}
                      alt="Indonesia"
                      width={40}
                      height={40}
                    />
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
            {isAuthenticated ? (
              <Menu autoSelect={false} placement="bottom-end">
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      as={IconButton}
                      size="md"
                      isActive={isOpen}
                      variant="ghost"
                      colorScheme="none"
                      isRound
                    >
                      {isAuthenticated ? (
                        <Avatar size="md" src={auth.user.picture} />
                      ) : (
                        <Icon as={FaUserCircle} boxSize="10" />
                      )}
                    </MenuButton>
                    <MenuList
                      maxWidth="sm"
                      rounded="md"
                      shadow="lg"
                      border="none"
                      background="white"
                      margin="0"
                      paddingX="2"
                      paddingY="3"
                    >
                      <Stack
                        justify="center"
                        align="center"
                        color="black"
                        padding={4}
                        width="full"
                      >
                        {isAuthenticated ? (
                          <Avatar size="lg" src={auth.user.picture} />
                        ) : (
                          <Icon
                            as={FaUserCircle}
                            color="gray.400"
                            boxSize="16"
                          />
                        )}
                        <Box paddingY={3} align="center">
                          <Text as="div" fontSize="lg" fontWeight={700}>
                            {auth.user.email}
                          </Text>
                        </Box>
                        <Button
                          colorScheme="red"
                          width="100%"
                          onClick={() => router.push('/logout')}
                        >
                          Daftar Keluar
                        </Button>
                      </Stack>
                    </MenuList>
                  </>
                )}
              </Menu>
            ) : (
              <Button colorScheme="white" variant="outline" onClick={onOpen}>
                Login
              </Button>
            )}
          </Stack>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login to Sinio</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Please enter your login details</Text>
            <Divider marginY={4} />
            <form onSubmit={handleSubmit(logIn)}>
              <Stack spacing="4">
                <FormControl isInvalid={errors.email} isRequired>
                  <FormLabel id="emailLabel" htmlFor="email">
                    Email
                  </FormLabel>
                  <Input
                    id="email"
                    size="lg"
                    variant="outline"
                    type="email"
                    {...register('email')}
                    style={{ background: 'white', color: 'black' }}
                  />
                  <FormErrorMessage id="emailError">
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.password} isRequired>
                  <FormLabel id="passwordLabel" htmlFor="password">
                    Password
                  </FormLabel>
                  <Input
                    id="password"
                    size="lg"
                    variant="outline"
                    type="password"
                    {...register('password')}
                    style={{ background: 'white', color: 'black' }}
                  />
                  <FormErrorMessage id="passwordError">
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                  <FormHelperText id="passwordHelper" align="right">
                    <NextLink href="/reset" passHref>
                      <Link
                        fontWeight={700}
                        color="blue.500"
                        width="full"
                        textAlign="right"
                      >
                        Forgot password?
                      </Link>
                    </NextLink>
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Stack>
                <Button
                  type="submit"
                  size="lg"
                  width="100%"
                  marginTop="8"
                  marginBottom="4"
                  isLoading={isLoggingIn}
                  loadingText="Login"
                  isDisabled={isLoggingIn}
                >
                  Login
                </Button>
                <Button
                  colorScheme="blue"
                  variant="link"
                  onClick={() => router.push('/register')}
                >
                  Create Account
                </Button>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Header
