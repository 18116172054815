import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

const SinioAPIService = ({ credentials = null, setAuth = null } = {}) => {
  const baseURL = process.env.NEXT_PUBLIC_SINIO_API_URL
  const config = {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      ...(credentials &&
        credentials.access_token && {
          Authorization: `Bearer ${credentials.access_token}`
        })
    }
  }

  const api = axios.create()
  const authApi = axios.create()

  // Handle expired token
  api.interceptors.request.use(
    async (c) => {
      try {
        if (c.headers.Authorization && c.url !== '/auth/logout') {
          const token = c.headers.Authorization.split(' ')[1]

          // * If access token has expired
          if (jwt_decode(token).exp < Date.now() / 1000 && credentials) {
            // * If setAuth method is defined
            if (setAuth && typeof setAuth === 'function') {
              const { data } = await refreshToken()

              // * Used to retrieve the old refresh token, as it does not change
              const newCredentials = {
                ...credentials,
                ...data
              }

              setAuth({
                credentials: newCredentials
              })

              // * Replaces access token for subsequent requests using this instance
              if (newCredentials?.access_token) {
                config.headers.Authorization = `Bearer ${newCredentials.access_token}`
                c.headers.Authorization = `Bearer ${newCredentials.access_token}`
              }
            } else {
              window.location = '/logout?sessionexpired=1'
            }
          }
        }

        return c
      } catch (e) {
        Sentry.captureException(e)
        window.location = '/logout?sessionexpired=1'
        return Promise.reject(e)
      }
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  // Auth
  const register = ({
    firstName,
    middleName,
    lastName,
    email,
    phoneNumber,
    password
  }) =>
    api.post(
      '/auth/signup',
      {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        phone: phoneNumber,
        email,
        password
      },
      config
    )

  const logIn = ({ email, password }) =>
    api.post(
      '/auth/login',
      {
        email,
        password
      },
      config
    )

  const logOut = () =>
    api.post(
      '/auth/logout',
      {
        refresh_token: credentials.refresh_token
      },
      config
    )

  const resetPassword = ({ email }) =>
    api.delete('/auth/password', {
      ...config,
      data: {
        email
      }
    })

  const refreshToken = () => {
    return authApi.post(
      '/auth/refresh-token',
      {
        refresh_token: credentials.refresh_token
      },
      config
    )
  }

  // Events

  const getEvents = ({
    isPublic = true,
    ids = [],
    past = 0,
    upcoming = 0,
    limit = 30,
    page = 1,
    currency
  }) =>
    api.get(`/${isPublic ? 'public_api' : 'api'}/events`, {
      ...config,
      params: {
        ...(ids.length && { ids: ids.toString() }),
        past,
        upcoming,
        currency,
        request_page_number: page,
        records_per_page: limit
      }
    })

  const getEvent = ({ isPublic = true, id, currency }) =>
    api.get(`/${isPublic ? 'public_api' : 'api'}/events/${id}`, {
      ...config,
      params: { currency },
      withCredentials: true
    })

  const buyTicket = ({ eventId, token, currency }) => {
    // Use the current access token if no custom token is provided
    if (token == null) {
      return api.post(
        '/api/payments/buy',
        {
          event: eventId,
          currency
        },
        config
      )
    }

    const decodedToken = decodeURI(token)

    // * Create new config object and updates the access token, if a custom token is provided
    const customConfig = JSON.parse(JSON.stringify(config))
    customConfig.headers.Authorization = `Bearer ${decodedToken}`

    return api.post(
      '/public_api/payments/buy',
      {
        event: eventId
      },
      customConfig
    )
  }

  const getCountry = () => api.get('https://freeipapi.com/api/json')

  return {
    register,
    logIn,
    logOut,
    resetPassword,
    getEvents,
    getEvent,
    buyTicket,
    getCountry
  }
}

export default SinioAPIService
