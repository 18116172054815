import { Flex, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'

const Footer = () => {
  const country = useStoreState((state) => state.country)

  return (
    <Flex as="footer" width="100%" direction="column" alignItems="center">
      <Stack
        width="full"
        maxWidth="8xl"
        align="center"
        justify={{ base: 'center', md: 'space-between' }}
        direction={{ base: 'column', md: 'row' }}
        paddingY={{ base: '6', md: '7', lg: '8' }}
        paddingX={{ base: '3', md: '4', lg: '5' }}
      >
        <Text as="div">
          {`© ${new Date().getFullYear()} Sinio. All Rights Reserved.`}
        </Text>
        <Text as="div">
          {country.currency === 'MYR'
            ? 'Sinio Streaming Sdn. Bhd.'
            : 'PT Asmara Hotel dan Properti'}
        </Text>
        <Stack as="nav" direction="row" spacing="4">
          <Link
            href="https://www.instagram.com/sinio4k"
            target="_blank"
            rel="noreferrer"
          >
            <Icon as={FaInstagram} boxSize={6} color="white" />
          </Link>
          <Link
            href="https://www.facebook.com/SinioAdmin"
            target="_blank"
            rel="noreferrer"
          >
            <Icon as={FaFacebook} boxSize={6} color="white" />
          </Link>
          <Link
            href="https://www.youtube.com/sinio"
            target="_blank"
            rel="noreferrer"
          >
            <Icon as={FaYoutube} boxSize={6} color="white" />
          </Link>
        </Stack>
      </Stack>
    </Flex>
  )
}

export default Footer
